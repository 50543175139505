import React from 'react'
import aboutImg from '../assets/about.png'

const About = () => {
  return (
    <div className='text-white max-w-[1200px] mx-auto my-12' id='about'>
    <div className='md:grid md:grid-cols-2 sm:py-16'>
      <div className='mt-4 md:mt-0 text-left flex'>
        <div className="my-auto mx-6">
            <h2 className='text-4xl font-bold mb-4 primary-color'>About Me</h2>
            <p className='text-base lg:text-lg'>
            Hi there,

            My name is Shibam Jha, and I am an undergraduate student currently pursuing a B.Tech in Information Technology. I am in my final year and actively seeking opportunities to advance my career, enhance my skill set, and gain valuable experience.

            My skills, as mentioned above, include proficiency in various technologies and tools. Additionally, I have substantial knowledge of Node.js and Express.js for building server-side applications. In terms of programming languages, I have a good grasp of Java, which I have utilized in various small projects and coursework.

            I am eager to showcase my abilities and collaborate with others to achieve mutual growth and success. Let's work together and make a positive impact!
            </p>
        </div>
        </div>

        <img alt='About' className='mx-auto rounded-3xl py-8 md:py-0' src={aboutImg} width={300} height={300}/>
      </div>
    </div>
  )
}

export default About
