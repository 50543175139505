import React from 'react'
import proj1 from '../assets/project-1.png'
import proj2 from '../assets/project-2.png'
import proj3 from '../assets/project-3.png'
const Projects = () => {
  return (
    <div className='max-w-[1200px] mx-auto p-5'>
      <div className='pb-8'>
        <p className='text-4xl mb-3 font-bold primary-color'>Project</p>
        <p className='text-gray-400'>Check out some of my recent work</p>
      </div>

      <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden
        shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center
        mx-auto content-div h-[200px] bg-cover relative'>
            <img src={proj1} layout="fill" objectfit="cover" alt=''/>
            <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
            <span className='text-2x1 font-bold text-white tracking-wider'>Location Tracker</span>
            <div className='pt-8 text-center'>
                <a href="https://github.com/Shibamjha/Realtime-Tracker">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700
                    font-bold text-lg'>
                        Repo
                    </button>
                </a>
            </div>
            </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden
        shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center
        mx-auto content-div h-[200px] bg-cover relative'>
            <img src={proj2} layout="fill" objectfit="cover" alt=''/>
            <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
            <span className='text-2x1 font-bold text-white tracking-wider'>Fitness Tracker</span>
            <div className='pt-8 text-center'>
                <a href="https://github.com/Shibamjha/Angular">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700
                    font-bold text-lg'>
                        Repo
                    </button>
                </a>
            </div>
            </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden
        shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center
        mx-auto content-div h-[200px] bg-cover relative'>
            <img src={proj3} layout="fill" objectfit="cover" alt=''/>
            <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
            <span className='text-2x1 font-bold text-white tracking-wider'>Sundown Studio</span>
            <div className='pt-8 text-center'>
                <a href="/">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700
                    font-bold text-lg'>
                        Repo
                    </button>
                </a>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
